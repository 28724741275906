import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  TextField,
  CardContent,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate, useParams } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';
import { BallTriangle } from 'react-loader-spinner';

const ProductsAttributesAdd = () => {
  const { productId, categoryId } = useParams();
  const navigate = useNavigate();
  const postU = usePost();
  const getU = useGet();

  const [values, setValues] = useState({
    value: '',
    categoryAttributeId: ''
  });
  const [errors, setErrors] = useState({
    value: false,
    categoryAttributeId: false
  });

  const [categoryAttributes, setCategoryAttributes] = useState([]);

  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isShowLoader, setIsShowLoader] = useState(false);

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const loadCategoryAttributes = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    getU(`categories/attributes?limit=all&categoryId=${categoryId}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setCategoryAttributes(resp.data.categoriesAttributes);
        }
      })
      .catch((err) => {
        showAlert('error', err.response.data.message);
        console.log(err.response);
        setIsDataLoading(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.value === '') {
      validComplete = false;
      formErrors.value = false;
      showAlert('error', 'Поле Значение не должно быть пустым');
    }

    if (values.categoryAttributeId === '') {
      validComplete = false;
      formErrors.categoryAttributeId = false;
      showAlert('error', 'Поле Атрибут не должно быть пустым');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const clearForm = () => {
    setValues({
      categoryAttributeId: '',
      value: ''
    });
  };

  const submit = async () => {
    if (validate()) {
      setIsShowLoader(true);
      setSubmitDisabled(true);

      const data = {
        value: values.value,
        categoryAttributeId: values.categoryAttributeId,
        productId
      };

      postU('products/attributes', data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Аттрибут добавлен');
            clearForm();
          } else {
            showAlert('error', 'Ошибка');
          }
        })
        .catch((err) => {
          showAlert('error', err.response.data.message);
          setIsShowLoader(false);
          setSubmitDisabled(false);
        })
        .finally(() => {});
    }
  };

  useEffect(() => {
    loadCategoryAttributes();
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Создание атрибута</title>
      </Helmet>
      <Box sx={{ pt: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Back
          </Button>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <form>
              <Card>
                <CardHeader title={`Добавление нового атрибута`} />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="categoryAttributeId">Атрибут</InputLabel>
                    <Select
                      labelId="categoryAttributeId"
                      name="categoryAttributeId"
                      value={values.categoryAttributeId}
                      label="Атрибут"
                      onChange={handleChange}
                    >
                      {categoryAttributes.map((attr) => (
                        <MenuItem value={attr.id}>{attr.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    label="Значение"
                    margin="normal"
                    name="value"
                    onChange={handleChange}
                    type="text"
                    value={values.value}
                    variant="outlined"
                    error={errors.value}
                  />

                  <Alert
                    severity={alert.type}
                    style={{ display: alert.isVisible ? 'flex' : 'none' }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ProductsAttributesAdd;
