import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Alert,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet, usePost } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import Input from '@material-ui/core/Input';
import ModalErrors from '../../components/ModalErrors/ModalErrors';
import { ChevronLeft } from 'react-feather';

const InactiveProducts = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const getU = useGet();
  const postU = usePost();
  const deleteU = useDelete();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [modalErrors, setModalErrors] = useState({
    isOpen: false,
    errors: null
  });

  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    title: searchParams.get('title') || '',
    categoryId: searchParams.get('categoryId') || '',
    page: searchParams.get('page') || 0,
    limit: searchParams.get('limit') || 10
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  const handleChangeQueryParams = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value
    });
  };

  const handleFilterQueryParams = () => {
    const params = {};
    if (queryParams.title !== '') {
      params.title = queryParams.title;
    }
    if (queryParams.categoryId !== '') {
      params.categoryId = queryParams.categoryId;
    }

    if (Object.keys(params).length !== 0) {
      // setSearchParams(params);
      setSearchParams({
        ...params,
        page: 0,
        limit: 10
      });
      setPage(0);
      setLimit(10);
    }
  };

  const loadProducts = () => {
    setIsLoaded(true);

    let endpoint = `products/inactive?page=${
      Number(searchParams.get('page')) + 1
    }&limit=${Number(searchParams.get('limit'))}`;

    if (queryParams.title && searchParams.get('title') !== null) {
      endpoint += `&title=${searchParams.get('title')}`;
    } else {
      setQueryParams({
        ...queryParams,
        title: ''
      });
    }

    if (queryParams.categoryId && searchParams.get('categoryId') !== null) {
      endpoint += `&categoryId=${searchParams.get('categoryId')}`;
    } else {
      setQueryParams({
        ...queryParams,
        categoryId: ''
      });
    }

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setData(resp.data.products);
          setCount(resp.data.currentCount || 0);
          // setLimit(resp.data.limit)
          // setPage(Number(resp.data.page))
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении товаров, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        setData([]);
        setCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const loadCategories = () => {
    setIsLoaded(true);

    let endpoint = `categories/?limit=all`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setCategories(resp.data.categories);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении категорий, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        setCategories([]);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (queryParams.title !== '') {
      setSearchParams({
        title: queryParams.title,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    } else if (queryParams.categoryId !== '') {
      setSearchParams({
        categoryId: queryParams.categoryId,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    } else {
      setSearchParams({
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    }
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
    if (queryParams.title !== '') {
      setSearchParams({
        title: queryParams.title,
        page: 1,
        limit: event.target.value
      });
    } else if (queryParams.type !== '') {
      setSearchParams({
        categoryId: queryParams.categoryId,
        page: 0,
        limit: event.target.value
      });
    } else {
      setSearchParams({
        page: 0,
        limit: event.target.value
      });
    }
  };

  const onRestore = (id) => {
    confirm({
      title: 'Публикация',
      content: 'Вы уверены, что хотите опубликовать товар?',
      onConfirm: () => {
        deleteU(`products/inactive/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadProducts();
            } else {
              showAlert(
                'error',
                'Произошла ошибка при попытке опубликовать товар'
              );
            }
          })
          .catch((e) => {
            showAlert('error', e.response.data.message);
          });
      }
    });
  };

  useEffect(() => {
    if (queryParams.title === '') {
      searchParams.delete('title');
      setSearchParams(searchParams);
    }
    if (queryParams.categoryId === '') {
      searchParams.delete('categoryId');
      setSearchParams(searchParams);
    }
  }, [queryParams]);

  useEffect(() => {
    loadProducts();
  }, [searchParams]);

  useEffect(() => {
    loadCategories();
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Товары</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button
            startIcon={<ChevronLeft />}
            onClick={() => navigate('/app/products')}
          >
            Назад
          </Button>
          Товары
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate('/app/products')}>Товары</li>
          <li>/</li>
          <li>Неопубликованные товары</li>
        </ul>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          <Alert
            severity={alert.type}
            style={{
              display: alert.isVisible ? 'flex' : 'none',
              marginBottom: 10
            }}
          >
            {alert.txt}
          </Alert>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1500 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mx: 2,
                      mb: 1
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Название товара"
                      margin="normal"
                      name="title"
                      onChange={handleChangeQueryParams}
                      type="text"
                      value={queryParams.title}
                      variant="outlined"
                      style={{ marginRight: 10 }}
                    />
                    <FormControl
                      fullWidth
                      sx={{ mt: 2, mb: 1 }}
                      style={{ marginRight: 10 }}
                    >
                      <InputLabel id="categoryId">Категория</InputLabel>
                      <Select
                        labelId="categoryId"
                        name="categoryId"
                        value={queryParams.categoryId}
                        label="Категория"
                        onChange={handleChangeQueryParams}
                      >
                        {[
                          <MenuItem value={''}>Все</MenuItem>,
                          ...categories.map((category) => {
                            return (
                              <MenuItem value={category.id}>
                                {category.name}
                              </MenuItem>
                            );
                          })
                        ]}
                      </Select>
                    </FormControl>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleFilterQueryParams}
                      sx={{ mt: 2, mb: 1 }}
                      style={{ minWidth: 120 }}
                    >
                      Применить
                    </Button>
                  </Box>
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: 80 }}>Id</TableCell>
                        <TableCell>Картинка</TableCell>
                        <TableCell>Название</TableCell>
                        <TableCell>Подкатегория</TableCell>
                        <TableCell>Категория</TableCell>
                        <TableCell>Бренд</TableCell>
                        <TableCell>Можно ли покупать несколько?</TableCell>
                        <TableCell>Дата создания</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((item) => (
                        <TableRow hover key={item.id}>
                          <TableCell sx={{ width: 80 }}>{item?.id}</TableCell>
                          <TableCell>
                            <Box sx={{ alignItems: 'center', display: 'flex' }}>
                              <Box
                                component="img"
                                sx={{
                                  height: 50,
                                  width: 50,
                                  maxHeight: { xs: 233, md: 167 },
                                  maxWidth: { xs: 350, md: 250 }
                                }}
                                alt="img"
                                src={
                                  item?.imageUris?.length
                                    ? `${process.env.REACT_APP_API_URL}/uploads/products/${item?.imageUris[0]}`
                                    : '/static/images/defphoto.jpg'
                                }
                              />
                            </Box>
                          </TableCell>
                          <TableCell>{item?.title || '---'}</TableCell>
                          <TableCell>
                            {item?.subcategory?.name || '---'}
                          </TableCell>
                          <TableCell>
                            {item?.subcategory?.category?.name || '---'}
                          </TableCell>
                          <TableCell>{item?.brand || '---'}</TableCell>
                          <TableCell>
                            {data?.isMultiplyBuyable ? 'Да' : 'Нет' || '---'}
                          </TableCell>
                          <TableCell>
                            {(item?.creationDate &&
                              new Date(item.creationDate).toLocaleString()) ||
                              '---'}
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ display: 'flex' }}>
                                <Box sx={{ ml: 2 }}>
                                  <Button
                                    color="success"
                                    variant="contained"
                                    onClick={() => onRestore(item.id)}
                                  >
                                    Опубликовать
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage={
                            <span>Кол-во строк на странице:</span>
                          }
                          rowsPerPageOptions={[10, 20, 30, 40, 50]}
                          colSpan={7}
                          count={count}
                          page={Number(searchParams.get('page'))}
                          rowsPerPage={
                            Number(searchParams.get('limit')) === 0
                              ? 10
                              : Number(searchParams.get('limit'))
                          }
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeLimit}
                        />
                        {console.log(Number(searchParams.get('limit')))}
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
        <ModalErrors
          isOpenModal={modalErrors}
          setIsOpenModal={setModalErrors}
        />
      </Box>
    </>
  );
};

export default InactiveProducts;
