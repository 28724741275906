import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Card,
  Button,
  TextField,
  Divider,
  InputLabel,
  Select,
  Alert,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Typography,
  CardHeader,
  CardContent
} from '@material-ui/core';
import { useGet, usePut } from '../../../API/request';
import { BallTriangle } from 'react-loader-spinner';

const RedSms = () => {
  const getU = useGet();
  const putU = usePut();

  const [isLoaded, setIsLoaded] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [values, setValues] = useState({
    login: '',
    key: ''
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const submit = () => {
    setSubmitDisabled(true);

    const data = {
      key: values.key,
      login: values.login
    };

    putU('sms/api', data)
      .then((response) => {
        if (response.status === 'success') {
          showAlert('success', 'Вы успешно обновили данные RedSms');
        } else {
          showAlert(
            'error',
            'Произошла ошибка при попытке обновить данные RedSms'
          );
        }
      })
      .catch((e) => {
        showAlert('error', e?.response?.data?.message);
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  useEffect(() => {
    setIsLoaded(true);
    getU('sms/api')
      .then((response) => {
        if (response.status === 'success') {
          setValues({
            key: response.data.smsApi.key,
            login: response.data.smsApi.login
          });
        } else {
          showAlert(
            'error',
            'Произошла ошибка при попытке получить данные RedSms'
          );
        }
      })
      .catch((e) => {
        showAlert('error', e?.response?.data?.message);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>RedSms</title>
      </Helmet>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          <Card>
            <CardHeader title="Конфигурация RedSms" />
            <Divider />
            <CardContent sx={{ position: 'relative' }}>
              <TextField
                fullWidth
                label="Логин"
                margin="normal"
                name="login"
                onChange={handleChange}
                type="text"
                value={values.login}
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Api ключ"
                margin="normal"
                name="key"
                onChange={handleChange}
                type="text"
                value={values.key}
                variant="outlined"
              />
              <Alert
                severity={alert.type}
                style={{ display: alert.isVisible ? 'flex' : 'none' }}
              >
                {alert.txt}
              </Alert>
            </CardContent>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
              <Button
                color="primary"
                variant="contained"
                onClick={submit}
                disabled={submitDisabled}
              >
                Сохранить
              </Button>
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default RedSms;
