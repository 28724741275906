import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CircularProgress,
  TextField,
  Alert,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import UserFormEdit from '../../components/Users/UserFormEdit';
import { useGet, usePut } from '../../API/request';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/Avatar/style.css';

const OrderEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const getU = useGet();
  const putU = usePut();

  const [isLoaded, setIsLoaded] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [nothingChanged, setNothingChanged] = useState(true);
  const [orderStatuses, setOrderStatuses] = useState([]);

  const [values, setValues] = useState({
    status: ''
  });
  const [errors, setErrors] = useState({
    status: false
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const handleChange = (event) => {
    setNothingChanged(false);

    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 2500);
  };

  const validateInfo = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.name === '') {
      validComplete = false;
      formErrors.role = false;
      showAlert('error', 'Поле Статус не должно быть пустым');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const submitInfo = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }

    if (validateInfo()) {
      setSubmitDisabled(true);

      const data = {
        status: values.status
      };

      putU(`orders/${id}`, data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Данные успешно обновлены');
          } else {
            showAlert('error', 'Ошибка');
          }
        })
        .catch((err) => {
          showAlert('error', err.response.data.message);
        })
        .finally(() => {
          setSubmitDisabled(false);
        });
    }
  };

  const loadOrderStatuses = () => {
    setIsLoaded(true);

    let endpoint = `orders/statuses`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setOrderStatuses(resp.data.statuses);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении статусов заказа, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        setOrderStatuses([]);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  useEffect(() => {
    console.log(id);

    setIsLoaded(true);
    getU(`orders/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          const data = {
            status: resp.data.order.status
          };

          setValues(data);
        }
      })
      .catch(() => {
        showAlert('error', 'Произошла ошибка при загрузке заказа');
      })
      .finally(() => {
        setIsLoaded(false);
      });

    loadOrderStatuses();
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Редактирование заказа</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Заказы
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Заказы</li>
          <li>/</li>
          <li>Редактирование</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box>
            <form>
              <Card>
                <CardHeader title={`Редактирование заказа №${id}`} />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <FormControl
                    fullWidth
                    sx={{ mt: 2, mb: 1 }}
                    style={{ marginRight: 10 }}
                  >
                    <InputLabel id="categoryId">Статус</InputLabel>
                    <Select
                      labelId="status"
                      name="status"
                      value={values.status}
                      label="Статус"
                      onChange={handleChange}
                    >
                      {[
                        ...orderStatuses.map((status) => {
                          return (
                            <MenuItem value={status} key={status}>
                              {status}
                            </MenuItem>
                          );
                        })
                      ]}
                    </Select>
                  </FormControl>
                </CardContent>
                <Divider />
                <Alert
                  severity={alert.type}
                  style={{ display: alert.isVisible ? 'flex' : 'none' }}
                >
                  {alert.txt}
                </Alert>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitInfo}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default OrderEdit;
