import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Input,
  Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet, usePost } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import TokenStorage from '../../API/TokenStorage';
import { ChevronLeft } from 'react-feather';

const CategoriesList = () => {
  const { productsTypeId } = useParams();

  const confirm = useConfirm();
  const getU = useGet();
  const postU = usePost();
  const deleteU = useDelete();
  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [disableLoadTable, setDisableLoadTable] = useState(false);
  const [loadedTable, setLoadedTable] = useState('');

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  const loadCategories = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    let endpoint = `categories/?page=${page + 1}&limit=${limit}&productTypeId=${productsTypeId}`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setCategories(resp.data.categories);
          setCount(resp.data.currentCount || 0);
        }
        setIsDataLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setCategories([]);
        setCount(0);
        setIsDataLoading(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const XMLUpLoaded = (event) => {
    setLoadedTable(event.target.files[0]);
  };

  const onPostTable = () => {
    if (loadedTable !== '') {
      setDisableLoadTable(true);
      console.log('here');
      let data = new FormData();
      data.append('table', loadedTable);

      postU(`xlsx/categories`, data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Вы успешно загрузили таблицу');
            setLoadedTable('');
          } else {
            showAlert(
              'error',
              'Произошла ошибка при попытке загрузить таблицу'
            );
          }
        })
        .catch((e) => {
          showAlert('error', e.response.data.message);
        })
        .finally(() => {
          setDisableLoadTable(false);
          setLoadedTable('');
          document.getElementById("dishXMLTable").value = null;
          loadCategories();
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить категорию?',
      onConfirm: () => {
        deleteU(`categories/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadCategories();
            }
          })
          .catch((e) => {});
      }
    });
  };

  useEffect(() => {
    loadCategories();
  }, [page, limit]);

  useEffect(() => {
    onPostTable();
  }, [loadedTable]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Категории товаров</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Категории товаров
        </Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          <Alert
            severity={alert.type}
            style={{
              display: alert.isVisible ? 'flex' : 'none',
              marginBottom: 10
            }}
          >
            {alert.txt}
          </Alert>
          {isDataLoading ? (
            <UserListSkelet />
          ) : (
            <>
              {TokenStorage.getUser().role == 'admin' && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box sx={{ marginLeft: 2 }}>
                    <input
                      type="file"
                      accept=".xlsx"
                      style={{ display: 'none' }}
                      id="dishXMLTable"
                      disabled={disableLoadTable}
                      onChange={XMLUpLoaded}
                    />
                    <label htmlFor="dishXMLTable">
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        component="span"
                        disabled={disableLoadTable}
                      >
                        Загрузить таблицу категорий
                      </Button>
                    </label>
                  </Box>
                  <Box sx={{ marginLeft: 2 }}>
                    <Link to={`/app/categories/add/${productsTypeId}`}>
                      <Button color="primary" variant="contained">
                        Добавить категорию
                      </Button>
                    </Link>
                  </Box>
                </Box>
              )}

              <Box sx={{ pt: 3 }}>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ minWidth: 400 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: 120 }}>Id</TableCell>
                            <TableCell sx={{ width: 350 }}>
                              Изображение
                            </TableCell>
                            <TableCell sx={{ width: 350 }}>Название</TableCell>
                            <TableCell sx={{ width: 350 }}>Приоритет</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {categories?.map((category) => (
                            <TableRow hover key={category.id}>
                              <TableCell sx={{ width: 80 }}>
                                {category?.id}
                              </TableCell>
                              <TableCell>
                                <Box
                                  component="img"
                                  sx={{
                                    height: 80,
                                    width: 130,
                                    maxHeight: { xs: 233, md: 167 },
                                    maxWidth: { xs: 350, md: 250 }
                                  }}
                                  alt="img"
                                  src={
                                    category?.imageUri
                                      ? `${process.env.REACT_APP_API_URL}/uploads/categories/${category.imageUri}`
                                      : '/static/images/defphoto.jpg'
                                  }
                                />
                              </TableCell>
                              <TableCell>{category?.name || '---'}</TableCell>
                              <TableCell>{category?.priority}</TableCell>
                              <TableCell>
                                <Box sx={{ display: 'flex' }}>
                                  <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ ml: 2 }}>
                                      <Link
                                        to={`/app/categories/sub/${category.id}`}
                                      >
                                        <Button
                                          color="primary"
                                          variant="contained"
                                        >
                                          Подкатегории
                                        </Button>
                                      </Link>
                                    </Box>
                                    {TokenStorage.getUser().role == 'admin' && (
                                      <>
                                        <Box sx={{ ml: 2 }}>
                                          <Link
                                            to={`/app/categories/attributes/${category.id}`}
                                          >
                                            <Button
                                              color="primary"
                                              variant="contained"
                                            >
                                              Аттрибуты
                                            </Button>
                                          </Link>
                                        </Box>
                                        <Box sx={{ ml: 2 }}>
                                          <Link
                                            to={`/app/categories/edit/${category.id}`}
                                          >
                                            <Button
                                              color="primary"
                                              variant="contained"
                                            >
                                              Редакт.
                                            </Button>
                                          </Link>
                                        </Box>
                                        <Box sx={{ ml: 2 }}>
                                          <Button
                                            color="error"
                                            variant="contained"
                                            onClick={() =>
                                              onDelete(category.id)
                                            }
                                          >
                                            Удалить
                                          </Button>
                                        </Box>
                                      </>
                                    )}
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              labelRowsPerPage={
                                <span>Кол-во строк на странице:</span>
                              }
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={7}
                              count={count}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeLimit}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default CategoriesList;
