import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import Account from './pages/Account/Account';
import UserInfo from './pages/User/UserInfo';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Logs from './pages/Systems/Logs/Logs';
import Dashboard from './pages/Dashboard/Dashboard';
import EmailTemplateList from './pages/Templates/Email-Templates/EmailTemplateList';
import EmailTemplateCreate from './pages/Templates/Email-Templates/EmailTemplateCreate';
import EmailTemplateEdit from './pages/Templates/Email-Templates/EmailTemplateEdit';
import CommonSettings from './pages/Systems/CommonSettings/CommonSettings';
import UpdateSystem from './pages/Systems/UpdateSystem/UpdateSystem';
import UserAdd from './pages/User/UserAdd';
import EmailHistoryList from './pages/Templates/Email-Hstory/EmailHistoryList';
import FeedBackList from './pages/FeedBacks/FeedBackList';
import FeedBackEdit from './pages/FeedBacks/FeedBackEdit';
import ConfigurationKeysList from './pages/ConfigurationKeys/ConfigurationKeysList';
import ConfigurationKeysAdd from './pages/ConfigurationKeys/ConfigurationKeysAdd';
import ConfigurationKeysEdit from './pages/ConfigurationKeys/ConfigurationKeysEdit';
import AppLogs from './pages/AppWork/AppLogs';
import AppStatistics from './pages/AppStatistics/AppStatistics';
import AppStatisticsEventsList from './pages/AppStatistics/AppStatisticsEventsList';
import AppStatisticsEventsAdd from './pages/AppStatistics/AppStatisticsEventsAdd';
import AppStatisticsEventsEdit from './pages/AppStatistics/AppStatisticsEventsEdit';
import ProductList from './pages/Products/ProductList';
import ProductAdd from './pages/Products/ProductAdd';
import ProductEdit from './pages/Products/ProductEdit';
import ProductInfo from './pages/Products/ProductInfo';
import InactiveProducts from './pages/Products/InactiveProducts';
import OrdersList from './pages/Orders/OrdersList';
import OrdersListMy from './pages/Orders/OrdersListMy';
import OrderEdit from './pages/Orders/OrderEdit';
import OrderInfo from './pages/Orders/OrderInfo';
import CategoryAdd from './pages/Categories/CategoryAdd';
import CategoryEdit from './pages/Categories/CategoryEdit';
import CategoriesList from './pages/Categories/CategoriesList';
import SubcategoriesList from './pages/Categories/SubcategoriesList';
import SubcategoryAdd from './pages/Categories/SubcategoryAdd';
import SubcategoryEdit from './pages/Categories/SubcategoryEdit';
import DocumentEdit from './pages/Documents/DocumentEdit';
import DocumentAdd from './pages/Documents/DocumentAdd';
import DocumentsList from './pages/Documents/DocumentsList';
import BannersList from './pages/Banners/BannersList';
import BannerAdd from './pages/Banners/BannerAdd';
import BannerEdit from './pages/Banners/BannerEdit';
import ReviewsList from './pages/Reviews/ReviewsList';
import ReviewsListMy from './pages/Reviews/ReviewsListMy';
import ReviewInfo from './pages/Reviews/ReviewInfo';
import ReviewEdit from './pages/Reviews/ReviewEdit';
import RedSms from './pages/Settings/RedSms/RedSms';
import EmailsList from './pages/Emails/EmailsList';
import CategoriesAttributesList from './pages/Categories/CategoriesAttributesList';
import CategoriesAttributesAdd from './pages/Categories/CategoriesAttributesAdd';
import CategoriesAttributesEdit from './pages/Categories/CategoriesAttributesEdit';
import ProductsAttributesEdit from './pages/Products/ProductsAttributesEdit';
import ProductsAttributesAdd from './pages/Products/ProductsAttributesAdd';
import ProductListMy from './pages/Products/ProductListMy';
import InactiveProductsMy from './pages/Products/InactiveProductsMy';
import ProductsTypeEdit from './pages/ProductsTypes/ProductsTypeEdit';
import ProductsTypeAdd from './pages/ProductsTypes/ProductsTypeAdd';
import ProductsTypesList from './pages/ProductsTypes/ProductsTypesList';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'dashboard', element: <Dashboard /> },

      { path: 'user/:id', element: <UserInfo /> },
      { path: 'user/edit/:id', element: <UserEdit /> },
      { path: 'user/add', element: <UserAdd /> },
      { path: 'users', element: <UserList /> },

      { path: 'products/inactive', element: <InactiveProducts /> },
      { path: 'products/info/:id', element: <ProductInfo /> },
      { path: 'products/edit/:id', element: <ProductEdit /> },
      { path: 'products/add', element: <ProductAdd /> },
      { path: 'products/my', element: <ProductListMy /> },
      { path: 'products/my/inactive', element: <InactiveProductsMy /> },
      { path: 'products', element: <ProductList /> },
      { path: 'products/attributes/:productId/:categoryId/add', element: <ProductsAttributesAdd /> },
      { path: 'products/attributes/:categoryId/edit/:id', element: <ProductsAttributesEdit /> },

      { path: 'categories/sub/:categoryId', element: <SubcategoriesList /> },
      { path: 'categories/sub/:categoryId/add', element: <SubcategoryAdd /> },
      { path: 'categories/sub/:categoryId/edit/:id', element: <SubcategoryEdit /> },

      { path: 'categories/attributes/:categoryId', element: <CategoriesAttributesList /> },
      { path: 'categories/attributes/:categoryId/add', element: <CategoriesAttributesAdd /> },
      { path: 'categories/attributes/:categoryId/edit/:id', element: <CategoriesAttributesEdit /> },

      { path: 'categories/edit/:id', element: <CategoryEdit /> },
      { path: 'categories/add/:productsTypeId', element: <CategoryAdd /> },
      { path: 'categories/:productsTypeId', element: <CategoriesList /> },

      { path: 'products/types/edit/:id', element: <ProductsTypeEdit /> },
      { path: 'products/types/add', element: <ProductsTypeAdd /> },
      { path: 'products/types', element: <ProductsTypesList /> },

      { path: 'orders/edit/:id', element: <OrderEdit /> },
      { path: 'orders/info/:id', element: <OrderInfo /> },
      { path: 'orders', element: <OrdersList /> },
      { path: 'orders/my', element: <OrdersListMy /> },

      { path: 'reviews/edit/:id', element: <ReviewEdit /> },
      { path: 'reviews/info/:id', element: <ReviewInfo /> },
      { path: 'reviews', element: <ReviewsList /> },
      { path: 'reviews/my', element: <ReviewsListMy /> },

      { path: 'documents/edit/:id', element: <DocumentEdit /> },
      { path: 'documents/add', element: <DocumentAdd /> },
      { path: 'documents', element: <DocumentsList /> },

      { path: 'banners/edit/:id', element: <BannerEdit /> },
      { path: 'banners/add', element: <BannerAdd /> },
      { path: 'banners', element: <BannersList /> },

      { path: 'feedbacks/edit/:id', element: <FeedBackEdit /> },
      { path: 'feedbacks', element: <FeedBackList /> },

      { path: 'redsms', element: <RedSms /> },

      { path: 'email-templates', element: <EmailTemplateList /> },
      { path: 'email-history', element: <EmailHistoryList /> },
      { path: 'email-templates/create', element: <EmailTemplateCreate /> },
      { path: 'email-templates/edit/:id', element: <EmailTemplateEdit /> },

      { path: 'emails', element: <EmailsList /> },

      { path: 'app-logs', element: <AppLogs /> },

      { path: 'app-statistics', element: <AppStatistics /> },
      { path: 'app-statistics/events', element: <AppStatisticsEventsList /> },
      {
        path: 'app-statistics/events/add',
        element: <AppStatisticsEventsAdd />
      },
      {
        path: 'app-statistics/events/edit/:id',
        element: <AppStatisticsEventsEdit />
      },

      { path: 'logs', element: <Logs /> },
      { path: 'common-settings', element: <CommonSettings /> },
      { path: 'update-system', element: <UpdateSystem /> },

      { path: 'configuration/keys', element: <ConfigurationKeysList /> },
      { path: 'configuration/keys/add', element: <ConfigurationKeysAdd /> },
      {
        path: 'configuration/keys/edit/:id',
        element: <ConfigurationKeysEdit />
      },

      { path: '', element: <Navigate to="/app/users" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/users" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
