import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Alert,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet, usePost } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import Input from '@material-ui/core/Input';
import ModalErrors from '../../components/ModalErrors/ModalErrors';

const OrdersList = () => {
  const confirm = useConfirm();
  const getU = useGet();
  const postU = usePost();
  const deleteU = useDelete();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [data, setData] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    userName: searchParams.get('userName') || '',
    userPhone: searchParams.get('userPhone') || '',
    status: searchParams.get('status') || '',
    page: searchParams.get('page') || 0,
    limit: searchParams.get('limit') || 10
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  const handleChangeQueryParams = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value
    });
  };

  const handleFilterQueryParams = () => {
    const params = {};
    if (queryParams.userName !== '') {
      params.userName = queryParams.userName;
    }
    if (queryParams.userPhone !== '') {
      params.userPhone = queryParams.userPhone;
    }
    if (queryParams.status !== '') {
      params.status = queryParams.status;
    }

    if (Object.keys(params).length !== 0) {
      // setSearchParams(params);
      setSearchParams({
        ...params,
        page: 0,
        limit: 10
      });
      setPage(0);
      setLimit(10);
    }
  };

  const loadOrders = () => {
    setIsLoaded(true);

    let endpoint = `orders/seller/my?page=${
      Number(searchParams.get('page')) + 1
    }&limit=${Number(searchParams.get('limit'))}`;

    if (queryParams.userEmail && searchParams.get('userEmail') !== null) {
      endpoint += `&userEmail=${searchParams.get('userEmail')}`;
    } else {
      setQueryParams({
        ...queryParams,
        userEmail: ''
      });
    }

    if (queryParams.userPhone && searchParams.get('userPhone') !== null) {
      endpoint += `&userPhone=${searchParams.get('userPhone')}`;
    } else {
      setQueryParams({
        ...queryParams,
        userPhone: ''
      });
    }

    if (queryParams.status && searchParams.get('status') !== null) {
      endpoint += `&status=${searchParams.get('status')}`;
    } else {
      setQueryParams({
        ...queryParams,
        status: ''
      });
    }

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setData(resp.data.orders);
          setCount(resp.data.currentCount || 0);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении заказов, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        setData([]);
        setCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const loadOrderStatuses = () => {
    setIsLoaded(true);

    let endpoint = `orders/statuses`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setOrderStatuses(resp.data.statuses);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении статусов заказа, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        setOrderStatuses([]);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (queryParams.userEmail !== '') {
      setSearchParams({
        userEmail: queryParams.userEmail,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    } else if (queryParams.userPhone !== '') {
      setSearchParams({
        userPhone: queryParams.userPhone,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    } else if (queryParams.status !== '') {
      setSearchParams({
        status: queryParams.status,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    } else {
      setSearchParams({
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    }
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
    if (queryParams.userEmail !== '') {
      setSearchParams({
        userEmail: queryParams.userEmail,
        page: 1,
        limit: event.target.value
      });
    } else if (queryParams.userPhone !== '') {
      setSearchParams({
        userPhone: queryParams.userPhone,
        page: 0,
        limit: event.target.value
      });
    } else if (queryParams.status !== '') {
      setSearchParams({
        status: queryParams.status,
        page: 0,
        limit: event.target.value
      });
    } else {
      setSearchParams({
        page: 0,
        limit: event.target.value
      });
    }
  };

  useEffect(() => {
    if (queryParams.userEmail === '') {
      searchParams.delete('userEmail');
      setSearchParams(searchParams);
    }
    if (queryParams.userPhone === '') {
      searchParams.delete('userPhone');
      setSearchParams(searchParams);
    }
    if (queryParams.status === '') {
      searchParams.delete('status');
      setSearchParams(searchParams);
    }
  }, [queryParams]);

  useEffect(() => {
    loadOrders();
  }, [searchParams]);

  useEffect(() => {
    loadOrderStatuses();
  }, [])

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Заказы</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Мои заказы</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          <Alert
            severity={alert.type}
            style={{
              display: alert.isVisible ? 'flex' : 'none',
              marginBottom: 10
            }}
          >
            {alert.txt}
          </Alert>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1500 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mx: 2,
                      mb: 1
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Email"
                      margin="normal"
                      name="userEmail"
                      onChange={handleChangeQueryParams}
                      type="text"
                      value={queryParams.userEmail}
                      variant="outlined"
                      style={{ marginRight: 10 }}
                    />
                    <TextField
                      fullWidth
                      label="Номер телефона"
                      margin="normal"
                      name="userPhone"
                      onChange={handleChangeQueryParams}
                      type="text"
                      value={queryParams.userPhone}
                      variant="outlined"
                      style={{ marginRight: 10 }}
                    />
                    <FormControl
                      fullWidth
                      sx={{ mt: 2, mb: 1 }}
                      style={{ marginRight: 10 }}
                    >
                      <InputLabel id="categoryId">Статус</InputLabel>
                      <Select
                        labelId="status"
                        name="status"
                        value={queryParams.status}
                        label="Статус"
                        onChange={handleChangeQueryParams}
                      >
                        {[
                          <MenuItem value={''}>Все</MenuItem>,
                          ...orderStatuses.map((status) => {
                            return (
                              <MenuItem value={status} key={status}>
                                {status}
                              </MenuItem>
                            );
                          })
                        ]}
                      </Select>
                    </FormControl>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleFilterQueryParams}
                      sx={{ mt: 2, mb: 1 }}
                      style={{ minWidth: 120 }}
                    >
                      Применить
                    </Button>
                  </Box>
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: 80 }}>Id</TableCell>
                        <TableCell>Имя заказчика</TableCell>
                        <TableCell>Фамилия</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Номер телефона</TableCell>
                        <TableCell>Статус</TableCell>
                        <TableCell>Товар</TableCell>
                        <TableCell>Пользователь</TableCell>
                        <TableCell>Количество</TableCell>
                        <TableCell>Дата заказа</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((item) => (
                        <TableRow hover key={item.id}>
                          <TableCell sx={{ width: 80 }}>{item?.id}</TableCell>
                          <TableCell>{item?.userName || '---'}</TableCell>
                          <TableCell>
                            {item?.userSurname || '---'}
                          </TableCell>
                          <TableCell>
                            {item?.userEmail || '---'}
                          </TableCell>
                          <TableCell>{item?.userPhone || '---'}</TableCell>
                          <TableCell>
                            {item?.status || '---'}
                          </TableCell>
                          <TableCell>
                            {item?.product && 
                            <Link to={`/app/products/info/${item.product.id}`} target="_blank">
                              {item.product.title}
                            </Link> || '---'}
                          </TableCell>
                          <TableCell>
                            {item?.user && 
                            <Link to={`/app/user/${item.user.id}`} target="_blank">
                              {item.user.email}
                            </Link> || '---'}
                          </TableCell>
                          <TableCell>
                            {item?.count || '---'}
                          </TableCell>
                          <TableCell>
                            {(item?.creationDate &&
                              new Date(item.creationDate).toLocaleString()) ||
                              '---'}
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ display: 'flex' }}>
                                <Link to={`/app/orders/info/${item?.id}`}>
                                  <Button color="primary" variant="contained">
                                    Инфо.
                                  </Button>
                                </Link>
                                <Box sx={{ ml: 2 }}>
                                  <Link to={`/app/orders/edit/${item.id}`}>
                                    <Button color="primary" variant="contained">
                                      Редакт.
                                    </Button>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage={
                            <span>Кол-во строк на странице:</span>
                          }
                          rowsPerPageOptions={[10, 20, 30, 40, 50]}
                          colSpan={7}
                          count={count}
                          page={Number(searchParams.get('page'))}
                          rowsPerPage={
                            Number(searchParams.get('limit')) === 0
                              ? 10
                              : Number(searchParams.get('limit'))
                          }
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeLimit}
                        />
                        {console.log(Number(searchParams.get('limit')))}
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default OrdersList;
