import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Alert,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet, usePost } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import Input from '@material-ui/core/Input';
import ModalErrors from '../../components/ModalErrors/ModalErrors';

const ReviewsList = () => {
  const confirm = useConfirm();
  const getU = useGet();
  const postU = usePost();
  const deleteU = useDelete();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    userId: searchParams.get('userId') || '',
    productId: searchParams.get('productId') || '',
    page: searchParams.get('page') || 0,
    limit: searchParams.get('limit') || 10
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  const handleChangeQueryParams = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value
    });
  };

  const handleFilterQueryParams = () => {
    const params = {};
    if (queryParams.productId !== '') {
      params.productId = queryParams.productId;
    }
    if (queryParams.userId !== '') {
      params.userId = queryParams.userId;
    }

    if (Object.keys(params).length !== 0) {
      // setSearchParams(params);
      setSearchParams({
        ...params,
        page: 0,
        limit: 10
      });
      setPage(0);
      setLimit(10);
    }
  };

  const loadReviews = () => {
    setIsLoaded(true);

    let endpoint = `reviews?page=${
      Number(searchParams.get('page')) + 1
    }&limit=${Number(searchParams.get('limit'))}`;

    if (queryParams.productId && searchParams.get('productId') !== null) {
      endpoint += `&productId=${searchParams.get('productId')}`;
    } else {
      setQueryParams({
        ...queryParams,
        productId: ''
      });
    }

    if (queryParams.userId && searchParams.get('userId') !== null) {
      endpoint += `&userId=${searchParams.get('userId')}`;
    } else {
      setQueryParams({
        ...queryParams,
        userId: ''
      });
    }

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setData(resp.data.reviews);
          setCount(resp.data.currentCount || 0);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении отзывов, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        setData([]);
        setCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (queryParams.productId !== '') {
      setSearchParams({
        productId: queryParams.productId,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    } else if (queryParams.userId !== '') {
      setSearchParams({
        userId: queryParams.userId,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    } else {
      setSearchParams({
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    }
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
    if (queryParams.productId !== '') {
      setSearchParams({
        productId: queryParams.productId,
        page: 1,
        limit: event.target.value
      });
    } else if (queryParams.userId !== '') {
      setSearchParams({
        userId: queryParams.userId,
        page: 0,
        limit: event.target.value
      });
    } else {
      setSearchParams({
        page: 0,
        limit: event.target.value
      });
    }
  };

  useEffect(() => {
    if (queryParams.productId === '') {
      searchParams.delete('productId');
      setSearchParams(searchParams);
    }
    if (queryParams.userId === '') {
      searchParams.delete('userId');
      setSearchParams(searchParams);
    }
  }, [queryParams]);

  useEffect(() => {
    loadReviews();
  }, [searchParams]);

  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить отзыв?',
      onConfirm: () => {
        deleteU(`reviews/${id}`).then((resp) => {
          if (resp.status === 'success') {
            loadReviews();
          }
        });
      }
    });
  };

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Отзывы</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Отзывы</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          <Alert
            severity={alert.type}
            style={{
              display: alert.isVisible ? 'flex' : 'none',
              marginBottom: 10
            }}
          >
            {alert.txt}
          </Alert>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1500 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mx: 2,
                      mb: 1
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Id товара"
                      margin="normal"
                      name="productId"
                      onChange={handleChangeQueryParams}
                      type="text"
                      value={queryParams.productId}
                      variant="outlined"
                      style={{ marginRight: 10 }}
                    />
                    <TextField
                      fullWidth
                      label="Id пользователя"
                      margin="normal"
                      name="userId"
                      onChange={handleChangeQueryParams}
                      type="text"
                      value={queryParams.userId}
                      variant="outlined"
                      style={{ marginRight: 10 }}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleFilterQueryParams}
                      sx={{ mt: 2, mb: 1 }}
                      style={{ minWidth: 120 }}
                    >
                      Применить
                    </Button>
                  </Box>
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: 80 }}>Id</TableCell>
                        <TableCell>Имя</TableCell>
                        <TableCell>Оценка</TableCell>
                        <TableCell>Текст </TableCell>
                        <TableCell>Товар</TableCell>
                        <TableCell>Пользователь</TableCell>
                        <TableCell>Дата создания</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((item) => (
                        <TableRow hover key={item.id}>
                          <TableCell sx={{ width: 80 }}>{item?.id}</TableCell>
                          <TableCell>{item?.userName || '---'}</TableCell>
                          <TableCell sx={{ color: 'orange' }}>
                            {'★'.repeat(item?.stars) || '---'}
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                maxWidth: 200,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                maxHeight: 60
                              }}
                            >
                              {item?.text || '---'}
                            </Box>
                          </TableCell>
                          <TableCell>
                            {(item?.product && (
                              <Link
                                to={`/app/products/info/${item.product.id}`}
                                target="_blank"
                              >
                                {item.product.title}
                              </Link>
                            )) ||
                              '---'}
                          </TableCell>
                          <TableCell>
                            {(item?.user && (
                              <Link
                                to={`/app/user/${item.user.id}`}
                                target="_blank"
                              >
                                {item.user.email}
                              </Link>
                            )) ||
                              '---'}
                          </TableCell>
                          <TableCell>
                            {(item?.creationDate &&
                              new Date(item.creationDate).toLocaleString()) ||
                              '---'}
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ display: 'flex' }}>
                                <Link to={`/app/reviews/info/${item?.id}`}>
                                  <Button color="primary" variant="contained">
                                    Инфо.
                                  </Button>
                                </Link>
                                <Box sx={{ ml: 2 }}>
                                  <Link to={`/app/reviews/edit/${item.id}`}>
                                    <Button color="primary" variant="contained">
                                      Редакт.
                                    </Button>
                                  </Link>
                                </Box>
                                <Box sx={{ ml: 2 }}>
                                      <Button
                                        color="error"
                                        variant="contained"
                                        onClick={() => onDelete(item.id)}
                                      >
                                        Удалить
                                      </Button>
                                    </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage={
                            <span>Кол-во строк на странице:</span>
                          }
                          rowsPerPageOptions={[10, 20, 30, 40, 50]}
                          colSpan={7}
                          count={count}
                          page={Number(searchParams.get('page'))}
                          rowsPerPage={
                            Number(searchParams.get('limit')) === 0
                              ? 10
                              : Number(searchParams.get('limit'))
                          }
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeLimit}
                        />
                        {console.log(Number(searchParams.get('limit')))}
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ReviewsList;
