import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  TextField,
  CardContent,
  Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';
import { convertToHTML } from 'draft-convert';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML
} from 'draft-js';

const BannerAdd = () => {
  const navigate = useNavigate();
  const postU = usePost();

  const [values, setValues] = useState({
    link: '',
    image: ''
  });
  const [errors, setErrors] = useState({
    link: false,
    image: false
  });

  const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');

  const [isShowLoader, setIsShowLoader] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const imgUploaded = (event) => {
    setUploadedImg(URL.createObjectURL(event.target.files[0]));
    setValues({
      ...values,
      image: event.target.files[0]
    });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.link === '') {
      validComplete = false;
      formErrors.link = false;
      showAlert('error', 'Поле Ссылка не должно быть пустым');
    }

    if (!values.image) {
      validComplete = false;
      formErrors.image = false;
      showAlert('error', 'Необходимо загрузить картинку');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const clearForm = () => {
    setValues({
      link: ''
    });
  };

  const submit = async () => {
    if (validate()) {
      setIsShowLoader(true);
      setSubmitDisabled(true);

      let data = new FormData();
      data.append("image", values.image);
      data.append("link", values.link)

      postU('banners', data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Баннер добавлен');
            clearForm();
          } else {
            showAlert('error', 'Ошибка');
          }
        })
        .catch((err) => {
          showAlert('error', err.response.data.message);
          setIsShowLoader(false);
          setSubmitDisabled(false);
        })
        .finally(() => {});
    }
  };

  return (
    <>
      <Helmet>
        <title>Баннеры</title>
      </Helmet>
      <Box sx={{ pt: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Back
          </Button>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <form>
              <Card>
                <CardHeader title="Добавление нового баннера" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
                    <Container maxWidth={false}>
                      <Box
                        sx={{
                          paddingBottom: 1
                        }}
                      >
                              <div className="itemWrapper">
                                <div className="container">
                                  <input
                                    accept=".jpg, .png, .gif"
                                    type="file"
                                    style={{ display: 'none' }}
                                    id={1}
                                    onChange={(event) => imgUploaded(event, 1)}
                                  />
                                  <label htmlFor={1}>
                                    <img
                                      src={uploadedImg}
                                      className=""
                                    />
                                    <div className="middle" />
                                  </label>
                                </div>
                                <div className="help-text">
                                  Доступны следующие расширения: .png .jpg .gif
                                </div>
                              </div>
                      </Box>
                    </Container>
                  </Box>
                  <TextField
                    fullWidth
                    label="Ссылка"
                    margin="normal"
                    name="link"
                    onChange={handleChange}
                    type="text"
                    value={values.link}
                    variant="outlined"
                    error={errors.link}
                  />
                  <Alert
                    severity={alert.type}
                    style={{ display: alert.isVisible ? 'flex' : 'none' }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BannerAdd;
