import { useEffect, useContext, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  Typography
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Users as UsersIcon,
  Mail as MailIcon,
  DollarSign as Payment,
  Settings,
  Activity,
  ChevronUp,
  ChevronsUp,
  Gift,
  Shield,
  Grid,
  Bookmark,
  Book,
  AlertOctagon,
  File,
  List as ListIcon,
  PhoneForwarded,
  Monitor,
  Columns,
  Archive,
  Layout,
  Image,
  MessageCircle
} from 'react-feather';
import NavItem from './NavItem';
import { useGet } from '../../API/request';
import { useSelector } from 'react-redux';

const itemsDesign = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Статистика'
  },
  {
    href: '/app/users',
    icon: UsersIcon,
    title: 'Пользователи',
    isAdminOnly: true
  },
  {
    icon: Columns,
    title: 'Товары',
    list: [
      {
        href: '/app/products/my',
        icon: Settings,
        title: 'Мои товары'
      },
      {
        href: '/app/products',
        icon: Settings,
        title: 'Товары',
        isAdminOnly: true
      }
    ]
  },
  {
    href: '/app/products/types',
    icon: ListIcon,
    title: 'Категории'
  },
  {
    href: '/app/orders',
    icon: Archive,
    title: 'Заказы',
    list: [
        {
          href: '/app/orders/my',
          icon: Settings,
          title: 'Мои заказы'
        },
        {
          href: '/app/orders',
          icon: Settings,
          title: 'Заказы',
          isAdminOnly: true
        }
      ]
  },
  {
    href: '/app/reviews',
    icon: MessageCircle,
    title: 'Отзывы',
    list: [
      {
        href: '/app/reviews/my',
        icon: Settings,
        title: 'Мои отзывы'
      },
      {
        href: '/app/reviews',
        icon: Settings,
        title: 'Отзывы',
        isAdminOnly: true
      }
    ]
  },
  {
    href: '/app/banners',
    icon: Image,
    title: 'Баннеры',
    isAdminOnly: true
  }
];

const itemsSystem = [
  {
    href: '/app/feedbacks',
    icon: File,
    title: 'Обратная связь',
    isAdminOnly: true
  },
  {
    icon: MailIcon,
    title: 'Работа с почтой',
    isAdminOnly: true,
    list: [
      {
        href: '/app/email-templates',
        icon: Settings,
        title: 'Шаблоны'
      },
      {
        href: '/app/email-history',
        icon: Settings,
        title: 'История'
      },
      {
        href: '/app/emails',
        icon: Settings,
        title: 'Рассылка'
      }
    ]
  },
  {
    href: '/app/settings',
    icon: Settings,
    title: 'Настройки',
    isAdminOnly: true,
    list: [
      {
        href: '/app/configuration/keys',
        icon: Settings,
        title: 'Конфигуратор ключей'
      },
      {
        href: '/app/documents',
        icon: Layout,
        title: 'Документы'
      },
      {
        href: '/app/redsms',
        icon: Layout,
        title: 'RedSms'
      }
    ]
  },
  {
    icon: Monitor,
    title: 'Работа приложения',
    isAdminOnly: true,
    list: [
      {
        href: '/app/app-logs',
        icon: Settings,
        title: 'Логи ошибок'
      },
      {
        href: '/app/app-statistics',
        icon: Settings,
        title: 'Статистика'
      }
    ]
  },
  {
    icon: AlertOctagon,
    title: 'Системные',
    isAdminOnly: true,
    list: [
      {
        href: '/app/common-settings',
        icon: Settings,
        title: 'Общие настройки'
      },
      {
        href: '/app/logs',
        icon: Settings,
        title: 'Логи'
      },
      {
        href: '/app/update-system',
        icon: Settings,
        title: 'Обновление системы'
      }
    ]
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const getU = useGet();
  const location = useLocation();
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    getU('users/current').then((res) => setUserData(res.data.user));
  }, []);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={`${process.env.REACT_APP_API_URL}/uploads/avatars/${userData.avatar}`}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64,
            marginBottom: 1
          }}
          to="/app/account"
        />
        <Typography color="textPrimary" variant="h5">
          {userData.email || 'Admin'}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ px: 2 }}>
        <List>
          {itemsDesign
            .filter((item) => !(userData.role != 'admin' && item.isAdminOnly))
            .map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                list={item?.list}
                userRole={userData.role}
              />
            ))}
        </List>
      </Box>
      <Divider />
      <Box sx={{ px: 2 }}>
        <List>
          {itemsSystem
            .filter((item) => !(userData.role != 'admin' && item.isAdminOnly))
            .map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                list={item?.list}
                userRole={userData.role}
              />
            ))}
        </List>
      </Box>
      <Typography
        color="textPrimary"
        variant="body2"
        sx={{ textAlign: 'center' }}
      >
        v{process.env.REACT_APP_VERSION} {process.env.REACT_APP_STATUS}
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Box>
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
