import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Divider,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  Alert,
  TextField,
  TableFooter,
  TablePagination,
  CardActionArea,
  CardMedia,
  TableHead,
  CardHeader
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom';
import { useDelete, useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BallTriangle } from 'react-loader-spinner';
import SimpleImageSlider from 'react-simple-image-slider';
import { useConfirm } from '../../components/Confirm';
import TokenStorage from '../../API/TokenStorage';

const ProductInfo = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const getU = useGet();
  const deleteU = useDelete();
  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [data, setData] = useState({});

  const [attributes, setAttributes] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить атрибут?',
      onConfirm: () => {
        deleteU(`products/attributes/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadAttributes();
            }
          })
          .catch((e) => {});
      }
    });
  };

  const loadAttributes = () => {
    let endpoint = `products/attributes?page=${
      page + 1
    }&limit=${limit}&productId=${id}`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setAttributes(resp.data.productsAttributes);
          setCount(resp.data.currentCount || 0);
        }
      })
      .catch((err) => {
        console.log(err);
        setAttributes([]);
        setCount(0);
      });
  };

  useEffect(() => {
    setIsLoaded(true);
    getU(`products/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setData(resp.data.product);
        } else {
          showAlert('error', 'Произошла ошибка при попытке получить товар');
        }
      })
      .catch(() => {
        showAlert('error', 'Произошла ошибка при попытке получить товар');
      })
      .finally(async () => {
        setIsLoaded(false);
      });

    loadAttributes();
  }, [id]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Товар</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          {data?.title}
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Товар</li>
          <li>/</li>
          <li>{data?.title}</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Alert
            severity={alert.type}
            style={{ display: alert.isVisible ? 'flex' : 'none' }}
          >
            {alert.txt}
          </Alert>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                {data.imageUris && (
                  <SimpleImageSlider
                    width={896}
                    height={504}
                    images={data.imageUris?.map(
                      (imageUri) =>
                        `${process.env.REACT_APP_API_URL}/uploads/products/${imageUri}`
                    )}
                    showBullets={true}
                    showNavs={true}
                  />
                )}
                <div className="info-block">
                  <div className="wrap">
                    <div className="label">ID:</div>
                    <div className="text">{data?.id || '---'}</div>
                  </div>

                  <div className="wrap">
                    <div className="label">Название:</div>
                    <div className="text">{data?.title || '---'}</div>
                  </div>

                  <div className="wrap">
                    <div className="label">Цена:</div>
                    <div className="text">{data?.price || '---'}</div>
                  </div>

                  <div className="wrap">
                    <div className="label">Старая цена:</div>
                    <div className="text">{data?.oldPrice || '---'}</div>
                  </div>

                  <div className="wrap">
                    <div className="label">Бренд:</div>
                    <div className="text">{data?.brand || '---'}</div>
                  </div>

                  <div className="wrap">
                    <div className="label">Категория:</div>
                    <div className="text">
                      {data?.subcategory?.category?.name || '---'}
                    </div>
                  </div>

                  <div className="wrap">
                    <div className="label">Подкатегория:</div>
                    <div className="text">
                      {data?.subcategory?.name || '---'}
                    </div>
                  </div>

                  <div className="wrap">
                    <div className="label">Теги:</div>
                    <div className="text">
                      {data?.tags?.join(', ') || '---'}
                    </div>
                  </div>

                  <div className="wrap">
                    <div className="label">Характеристики:</div>
                    <div className="text">{data?.characteristics || '---'}</div>
                  </div>
                  <div className="wrap">
                    <div className="label">Опубликовано:</div>
                    <div className="text">
                      {data?.isActive ? 'Да' : 'Нет' || '---'}
                    </div>
                  </div>

                  <div className="wrap">
                    <div className="label">Можно ли покупать несколько?:</div>
                    <div className="text">
                      {data?.isMultiplyBuyable ? 'Да' : 'Нет' || '---'}
                    </div>
                  </div>

                  <div className="wrap">
                    <div className="label">Популярный?:</div>
                    <div className="text">
                      {data?.isPopular ? 'Да' : 'Нет' || '---'}
                    </div>
                  </div>

                  <div className="wrap">
                    <div className="label">Серийный номер:</div>
                    <div className="text">{data?.serialNumber || '---'}</div>
                  </div>

                  <div className="wrap">
                    <div className="label">Дата создания:</div>
                    <div className="text">
                      {new Date(data?.creationDate).toLocaleString() || '---'}
                    </div>
                  </div>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
      </Box>

      {data?.description && (
        <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
          <Container maxWidth={false}>
            <Card>
              <Typography
                style={{ padding: 20 }}
                color="textPrimary"
                variant="h4"
              >
                Описание
              </Typography>
              <CardContent sx={{ p: 3 }}>
                <PerfectScrollbar>
                  <div
                    className="defaultStyle"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                </PerfectScrollbar>
              </CardContent>
            </Card>
          </Container>
        </Box>
      )}
      {TokenStorage.getUser().role == 'admin' && (
        <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
          <Container maxWidth={false}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
              <Box sx={{ marginLeft: 2 }}>
                <Link
                  to={`/app/products/attributes/${id}/${data?.subcategory?.category?.id}/add`}
                >
                  <Button color="primary" variant="contained">
                    Добавить атрибут
                  </Button>
                </Link>
              </Box>
            </Box>
            <Card>
              <CardHeader title="Атрибуты товара" />
              <Divider />
              <PerfectScrollbar>
                <Box sx={{ minWidth: 400 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: 120 }}>Id</TableCell>
                        <TableCell sx={{ width: 350 }}>Название</TableCell>
                        <TableCell sx={{ width: 350 }}>Значение</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attributes?.map((attribute) => (
                        <TableRow hover key={attribute.id}>
                          <TableCell sx={{ width: 80 }}>
                            {attribute?.id}
                          </TableCell>
                          <TableCell>
                            {attribute?.categoryAttribute?.name || '---'}
                          </TableCell>
                          <TableCell>{attribute?.value || '---'}</TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ display: 'flex' }}>
                                <Box sx={{ ml: 2 }}>
                                  <Link
                                    to={`/app/products/attributes/${data?.subcategory?.category?.id}/edit/${attribute.id}`}
                                  >
                                    <Button color="primary" variant="contained">
                                      Редакт.
                                    </Button>
                                  </Link>
                                </Box>
                                <Box sx={{ ml: 2 }}>
                                  <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => onDelete(attribute.id)}
                                  >
                                    Удалить
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage={
                            <span>Кол-во строк на странице:</span>
                          }
                          rowsPerPageOptions={[10, 20, 30, 40, 50]}
                          colSpan={7}
                          count={count}
                          rowsPerPage={limit}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeLimit}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Container>
        </Box>
      )}
    </>
  );
};

export default ProductInfo;
