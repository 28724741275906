import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  TextField,
  CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate, useParams } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import Typography from '@material-ui/core/Typography';
import { apiUrl } from '../../API/environment';

const ProductEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const postU = usePost();
  const getU = useGet();
  const putU = usePut();
  const [desc, setDesc] = useState('');

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(desc))
    )
  );

  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState({});
  const [subcategories, setSubcategories] = useState([]);

  const [uploadedImages, setUploadedImages] = useState([]);

  const [isLoaded, setIsLoaded] = useState(true);

  const [title, setTitle] = useState('');

  const [values, setValues] = useState({
    title: '',
    description: '',
    price: '',
    oldPrice: '',
    subcategoryId: '',
    images: [],
    brand: '',
    characteristics: '',
    tags: '',
    isMultiplyBuyable: true,
    isPopular: false,
    serialNumber: ''
  });
  const [errors, setErrors] = useState({
    name: false,
    description: false,
    price: false,
    oldPrice: false,
    images: false,
    subcategoryId: false,
    brand: false,
    characteristics: false,
    tags: false,
    isMultiplyBuyable: false,
    serialNumber: false
  });

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const imgUploaded = (event) => {
    const objectUrls = Object.values(event.target.files).map((file) =>
      URL.createObjectURL(file)
    );
    setUploadedImages([...uploadedImages, ...objectUrls]);
    setValues({
      ...values,
      images: [...values.images, ...Object.values(event.target.files)]
    });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.title === '') {
      validComplete = false;
      formErrors.title = false;
      showAlert('error', 'Поле Название не должно быть пустым');
    }
    if (values.price === '') {
      validComplete = false;
      formErrors.price = false;
      showAlert('error', 'Поле Цена не должно быть пустым');
    }
    if (values.price < 0) {
      validComplete = false;
      formErrors.price = false;
      showAlert('error', 'Цена не может быть отрицательной');
    }

    if (values.oldPrice < 0) {
      validComplete = false;
      formErrors.oldPrice = false;
      showAlert('error', 'Старая цена не может быть отрицательной');
    }


    if (!values.subcategoryId) {
      validComplete = false;
      formErrors.subcategoryId = false;
      showAlert('error', 'Подкатегория должна быть указана');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const submit = async () => {
    if (validate()) {
      setSubmitDisabled(true);

      const data = new FormData();

      data.append('title', values.title);
      data.append('description', values.description);
      data.append('price', values.price);
      data.append('oldPrice', values.oldPrice);
      data.append('subcategoryId', values.subcategoryId);
      data.append('isMultiplyBuyable', values.isMultiplyBuyable);
      data.append('isPopular', values.isPopular);
      data.append('serialNumber', values.serialNumber);
      data.append(
        'description',
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      );

      if (values.images.length) {
        for (const image of values.images) {
          data.append('images', image);
        }
      }
      if (values.brand) {
        data.append('brand', values.brand);
      }
      if (values.characteristics) {
        data.append('characteristics', values.characteristics);
      }
      if (values.tags) {
        data.append('tags', values.tags);
      }

      putU(`products/${id}`, data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Вы успешно изменили товар');
          } else {
            showAlert('error', 'Произошла ошибка при изменили товара');
          }
        })
        .catch((err) => {
          showAlert('error', err.response.data.message);
          setSubmitDisabled(false);
        })
        .finally(() => {});
    }
  };

  const blobUrlToFile = (blobUrl) =>
    new Promise((resolve) => {
      fetch(blobUrl).then((res) => {
        res.blob().then((blob) => {
          const ext = blobUrl.split('.').pop();
          const file = new File([blob], `image.${ext}`, { type: blob.type });
          resolve(file);
        });
      });
    });

  const getData = async () => {
    setIsLoaded(true);
    await getU(`categories?limit=all`)
      .then((resp) => {
        if (resp.status === 'success') {
          setCategories(resp.data.categories);
        } else {
          showAlert('error', 'Произошла ошибка при загрузке категорий');
        }
      })
      .catch(() => {
        showAlert('error', 'Произошла ошибка при загрузке категорий');
      });

    getU(`products/${id}`)
      .then(async (resp) => {
        if (resp.status === 'success') {
          const product = resp.data.product;

          const imageUrls = product.imageUris?.map(
            (uri) => `${apiUrl}/uploads/products/${uri}`
          );

          const images =
            imageUrls &&
            (await Promise.all(
              imageUrls?.map(async (url) => await blobUrlToFile(url))
            ));

          setUploadedImages(imageUrls || []);

          setTitle(product.title);

          setSelectedCategoryId(product.subcategory?.category.id);

          setValues({
            ...values,
            title: product.title,
            price: product.price,
            oldPrice: product.oldPrice,
            brand: product.brand,
            characteristics: product.characteristics,
            isMultiplyBuyable: product.isMultiplyBuyable,
            serialNumber: product.serialNumber,
            tags: product.tags?.join(', ') || '',
            images: images || [],
            subcategoryId: product.subcategoryId,
            isPopular: product.isPopular,
          });

          setEditorState(() =>
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(product?.description || '<p></p>')
              )
            )
          );
        } else {
          showAlert('error', 'Произошла ошибка при загрузке товара');
        }
      })
      .catch(() => {
        showAlert('error', 'Произошла ошибка при загрузке товара');
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const deleteImage = (index) => {
    setUploadedImages(uploadedImages.filter((img, ind) => ind != index));
    setValues({
      ...values,
      images: values.images.filter((img, ind) => ind != index)
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const category = categories.find((cat) => cat.id == selectedCategoryId);
    setSubcategories(category?.subcategories || []);
  }, [selectedCategoryId]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Товары</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          {title}
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Товары</li>
          <li>/</li>
          <li>{title}</li>
        </ul>
      </Box>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <form>
              <Card>
                <CardHeader title="Редактирование товара" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <div className="itemWrapper">
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                    >
                      {uploadedImages.map((img, index) => (
                        <div
                          className="container"
                          onClick={() => deleteImage(index)}
                        >
                          <img
                            src={img}
                            className="itemImg"
                            style={{ dispaly: 'block' }}
                            id={index}
                          />
                          <label htmlFor={index}>
                            <div className="middle--close" />
                          </label>
                        </div>
                      ))}

                      <div className="container">
                        <label htmlFor="input" className="labelInput">
                          <div className="itemImg" />
                          <div className="middle" />
                        </label>
                        <input
                          accept={[
                            ".gif",
                            ".png",
                            ".jpg",
                            ".jpeg",
                            ".svg",
                            ".webp",
                            ".tiff",
                            ".avif",
                          ].join(", ")}
                          type="file"
                          style={{ display: 'none' }}
                          id="input"
                          onChange={(event) => imgUploaded(event)}
                          multiple
                        />
                      </div>
                    </div>
                    <div className="help-text">
                      Доступны следующие расширения: .png, .jpg, .gif
                    </div>
                  </div>

                  <TextField
                    fullWidth
                    label="Название"
                    margin="normal"
                    name="title"
                    onChange={handleChange}
                    type="text"
                    value={values.title}
                    variant="outlined"
                    error={errors.title}
                  />
                  <TextField
                    fullWidth
                    label="Цена"
                    margin="normal"
                    name="price"
                    onChange={handleChange}
                    type="number"
                    value={values.price}
                    variant="outlined"
                    error={errors.price}
                  />
                  <TextField
                    fullWidth
                    label="Старая цена"
                    margin="normal"
                    name="oldPrice"
                    onChange={handleChange}
                    type="number"
                    value={values.oldPrice}
                    variant="outlined"
                    error={errors.oldPrice}
                  />
                  <TextField
                    fullWidth
                    label="Бренд"
                    margin="normal"
                    name="brand"
                    onChange={handleChange}
                    type="text"
                    value={values.brand}
                    variant="outlined"
                    error={errors.brand}
                  />
                  <TextField
                    fullWidth
                    label="Характеристики"
                    margin="normal"
                    name="characteristics"
                    onChange={handleChange}
                    type="text"
                    value={values.characteristics}
                    variant="outlined"
                    error={errors.characteristics}
                  />
                  <TextField
                    fullWidth
                    label="Теги (через запятую)"
                    margin="normal"
                    name="tags"
                    onChange={handleChange}
                    type="text"
                    value={values.tags}
                    variant="outlined"
                    error={errors.tags}
                  />

                  <TextField
                    fullWidth
                    label="Серийный номер"
                    margin="normal"
                    name="serialNumber"
                    onChange={handleChange}
                    type="text"
                    value={values.serialNumber}
                    variant="outlined"
                    error={errors.serialNumber}
                  />

                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="isMultiplyBuyable">
                      Можно ли покупать несколько?
                    </InputLabel>
                    <Select
                      labelId="isMultiplyBuyable"
                      name="isMultiplyBuyable"
                      value={values.isMultiplyBuyable}
                      label="Можно ли покупать несколько?"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Да</MenuItem>
                      <MenuItem value={false}>Нет</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="isPopular">
                      Популярный?
                    </InputLabel>
                    <Select
                      labelId="isPopular"
                      name="isPopular"
                      value={values.isPopular}
                      label="Популярный?"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Да</MenuItem>
                      <MenuItem value={false}>Нет</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="type">Категория</InputLabel>
                    <Select
                      labelId="category"
                      name="category"
                      value={selectedCategoryId}
                      label="Категория"
                      onChange={(e) => setSelectedCategoryId(e.target.value)}
                    >
                      {categories?.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="type">Подкатегория</InputLabel>
                    <Select
                      labelId="subcategoryId"
                      name="subcategoryId"
                      value={values.subcategoryId}
                      label="Подкатегория"
                      onChange={handleChange}
                    >
                      {subcategories?.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Typography
                    style={{ marginTop: 10, marginBottom: 10, fontSize: 20 }}
                  >
                    Описание
                  </Typography>

                  <Editor
                    editorStyle={{
                      border: '1px solid rgba(0,0,0,0.2)',
                      minHeight: 500,
                      padding: 10
                    }}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                  />

                  <Alert
                    severity={alert.type}
                    style={{ display: alert.isVisible ? 'flex' : 'none' }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ProductEdit;
