import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Alert,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet, usePost } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import Input from '@material-ui/core/Input';
import ModalErrors from '../../components/ModalErrors/ModalErrors';
import { apiUrl } from '../../API/environment';

const ProductListMy = () => {
  const confirm = useConfirm();
  const getU = useGet();
  const postU = usePost();
  const deleteU = useDelete();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [modalErrors, setModalErrors] = useState({
    isOpen: false,
    errors: null
  });

  const [disableLoadTable, setDisableLoadTable] = useState(false);
  const [loadedTable, setLoadedTable] = useState('');

  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    title: searchParams.get('title') || '',
    categoryId: searchParams.get('categoryId') || '',
    isPopular: searchParams.get('isPopular') || '',
    page: searchParams.get('page') || 0,
    limit: searchParams.get('limit') || 10
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  const XMLUpLoaded = (event) => {
    setLoadedTable(event.target.files[0]);
  };

  const onPostTable = () => {
    if (loadedTable !== '') {
      setDisableLoadTable(true);
      console.log('here');
      let data = new FormData();
      data.append('table', loadedTable);

      postU(`xlsx/products/`, data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Вы успешно загрузили таблицу');
            if (resp.data.errors.length) {
              setModalErrors({
                isOpen: true,
                errors: resp.data.errors
              });
            }
            setLoadedTable('');
          } else {
            showAlert(
              'error',
              'Произошла ошибка при попытке загрузить таблицу'
            );
          }
        })
        .catch((e) => {
          showAlert('error', e.response.data.message);
        })
        .finally(() => {
          setDisableLoadTable(false);
          setLoadedTable('');
          document.getElementById("dishXMLTable").value = null;
          loadProducts();
        });
    }
  };

  const handleChangeQueryParams = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value
    });
  };

  const handleFilterQueryParams = () => {
    const params = {};
    if (queryParams.title !== '') {
      params.title = queryParams.title;
    }
    if (queryParams.categoryId !== '') {
      params.categoryId = queryParams.categoryId;
    }
    if (queryParams.isPopular !== '') {
      params.isPopular = queryParams.isPopular;
    }

    if (Object.keys(params).length !== 0) {
      // setSearchParams(params);
      setSearchParams({
        ...params,
        page: 0,
        limit: 10
      });
      setPage(0);
      setLimit(10);
    }
  };

  const loadProducts = () => {
    setIsLoaded(true);

    let endpoint = `products/my?page=${
      Number(searchParams.get('page')) + 1
    }&limit=${Number(searchParams.get('limit'))}`;

    if (queryParams.title && searchParams.get('title') !== null) {
      endpoint += `&title=${searchParams.get('title')}`;
    } else {
      setQueryParams({
        ...queryParams,
        title: ''
      });
    }

    if (queryParams.categoryId && searchParams.get('categoryId') !== null) {
      endpoint += `&categoryId=${searchParams.get('categoryId')}`;
    } else {
      setQueryParams({
        ...queryParams,
        categoryId: ''
      });
    }

    if (queryParams.isPopular && searchParams.get('isPopular') !== null) {
      endpoint += `&isPopular=${searchParams.get('isPopular') == 'Да' ? 1 : 0}`;
    } else {
      setQueryParams({
        ...queryParams,
        isPopular: ''
      });
    }

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setData(resp.data.products);
          setCount(resp.data.currentCount || 0);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении товаров, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        setData([]);
        setCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const loadCategories = () => {
    setIsLoaded(true);

    let endpoint = `categories/?limit=all`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setCategories(resp.data.categories);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении категорий, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        setCategories([]);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (queryParams.title !== '') {
      setSearchParams({
        title: queryParams.title,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    } else if (queryParams.categoryId !== '') {
      setSearchParams({
        categoryId: queryParams.categoryId,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    } else if (queryParams.isPopular !== '') {
      setSearchParams({
        isPopular: queryParams.isPopular,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    } else {
      setSearchParams({
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    }
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
    if (queryParams.title !== '') {
      setSearchParams({
        title: queryParams.title,
        page: 1,
        limit: event.target.value
      });
    } else if (queryParams.categoryId !== '') {
      setSearchParams({
        categoryId: queryParams.categoryId,
        page: 0,
        limit: event.target.value
      });
    } else if (queryParams.isPopular !== '') {
      setSearchParams({
        isPopular: queryParams.isPopular,
        page: 0,
        limit: event.target.value
      });
    } else {
      setSearchParams({
        page: 0,
        limit: event.target.value
      });
    }
  };

  useEffect(() => {
    onPostTable();
  }, [loadedTable]);

  const onDelete = (id) => {
    confirm({
      title: 'Отмена публикации',
      content: 'Вы уверены, что хотите отменить публикацию товара?',
      onConfirm: () => {
        postU(`products/inactive/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadProducts();
            } else {
              showAlert('error', 'Произошла ошибка при попытке удалить товар');
            }
          })
          .catch((e) => {
            showAlert('error', e.response.data.message);
          });
      }
    });
  };

  useEffect(() => {
    if (queryParams.title === '') {
      searchParams.delete('title');
      setSearchParams(searchParams);
    }
    if (queryParams.categoryId === '') {
      searchParams.delete('categoryId');
      setSearchParams(searchParams);
    }
    if (queryParams.isPopular === '') {
      searchParams.delete('isPopular');
      setSearchParams(searchParams);
    }
  }, [queryParams]);

  useEffect(() => {
    loadProducts();
  }, [searchParams]);

  useEffect(() => {
    loadCategories();
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Товары</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Мои товары</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          <Alert
            severity={alert.type}
            style={{
              display: alert.isVisible ? 'flex' : 'none',
              marginBottom: 10
            }}
          >
            {alert.txt}
          </Alert>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{ marginLeft: 2 }}>
              <input
                accept=".xlsx"
                type="file"
                style={{ display: 'none' }}
                id="dishXMLTable"
                disabled={disableLoadTable}
                onChange={XMLUpLoaded}
              />
              <label htmlFor="dishXMLTable">
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  component="span"
                  disabled={disableLoadTable}
                >
                  Загрузить таблицу товаров
                </Button>
              </label>
            </Box>
            {
              data ?
            <Box sx={{ marginLeft: 2 }}>
              <a href={`${apiUrl}/api/products/xml?userId=${data[0]?.userId}`}>
                <Button color="error" variant="contained">
                  Выгрузить товары
                </Button>
              </a>
            </Box>
            : <></>
}
            <Box sx={{ marginLeft: 2 }}>
              <Link to="/app/products/my/inactive">
                <Button color="error" variant="contained">
                  Неопубликованные товары
                </Button>
              </Link>
            </Box>
            <Box sx={{ marginLeft: 2 }}>
              <Link to="/app/products/add">
                <Button color="warning" variant="contained">
                  Добавить товар
                </Button>
              </Link>
            </Box>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1600 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mx: 2,
                      mb: 1
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Название товара"
                      margin="normal"
                      name="title"
                      onChange={handleChangeQueryParams}
                      type="text"
                      value={queryParams.title}
                      variant="outlined"
                      style={{ marginRight: 10 }}
                    />
                    <FormControl
                      fullWidth
                      sx={{ mt: 2, mb: 1 }}
                      style={{ marginRight: 10 }}
                    >
                      <InputLabel id="categoryId">Категория</InputLabel>
                      <Select
                        labelId="categoryId"
                        name="categoryId"
                        value={queryParams.categoryId}
                        label="Категория"
                        onChange={handleChangeQueryParams}
                      >
                        {[
                          <MenuItem value={''}>Все</MenuItem>,
                          ...categories.map((category) => {
                            return (
                              <MenuItem value={category.id}>
                                {category.name}
                              </MenuItem>
                            );
                          })
                        ]}
                      </Select>
                    </FormControl>
                    <FormControl
                      fullWidth
                      sx={{ mt: 2, mb: 1 }}
                      style={{ marginRight: 10 }}
                    >
                      <InputLabel id="categoryId">Популярный?</InputLabel>
                      <Select
                        labelId="isPopular"
                        name="isPopular"
                        value={queryParams.isPopular}
                        label="Популярный?"
                        onChange={handleChangeQueryParams}
                      >
                        <MenuItem value={''}>Все</MenuItem>,
                        <MenuItem value={'Да'}>Да</MenuItem>
                        <MenuItem value={'Нет'}>Нет</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleFilterQueryParams}
                      sx={{ mt: 2, mb: 1 }}
                      style={{ minWidth: 120 }}
                    >
                      Применить
                    </Button>
                  </Box>
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: 80 }}>Id</TableCell>
                        <TableCell>Картинка</TableCell>
                        <TableCell>Название</TableCell>
                        <TableCell>Подкатегория</TableCell>
                        <TableCell>Категория</TableCell>
                        <TableCell>Бренд</TableCell>
                        <TableCell>Можно ли покупать несколько?</TableCell>
                        <TableCell>Популярный?</TableCell>
                        <TableCell>Дата создания</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((item) => (
                        <TableRow hover key={item.id}>
                          <TableCell sx={{ width: 80 }}>{item?.id}</TableCell>
                          <TableCell>
                            <Box sx={{ alignItems: 'center', display: 'flex' }}>
                              <Box
                                component="img"
                                sx={{
                                  height: 50,
                                  width: 50,
                                  maxHeight: { xs: 233, md: 167 },
                                  maxWidth: { xs: 350, md: 250 }
                                }}
                                alt="img"
                                src={
                                  item?.imageUris?.length
                                    ? `${process.env.REACT_APP_API_URL}/uploads/products/${item?.imageUris[0]}`
                                    : '/static/images/defphoto.jpg'
                                }
                              />
                            </Box>
                          </TableCell>
                          <TableCell>{item?.title || '---'}</TableCell>
                          <TableCell>
                            {item?.subcategory?.name || '---'}
                          </TableCell>
                          <TableCell>
                            {item?.subcategory?.category?.name || '---'}
                          </TableCell>
                          <TableCell>{item?.brand || '---'}</TableCell>
                          <TableCell>
                            {item?.isMultiplyBuyable ? 'Да' : 'Нет' || '---'}
                          </TableCell>
                          <TableCell>
                            {item?.isPopular ? 'Да' : 'Нет' || '---'}
                          </TableCell>
                          <TableCell>
                            {(item?.creationDate &&
                              new Date(item.creationDate).toLocaleString()) ||
                              '---'}
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ display: 'flex' }}>
                                <Link to={`/app/products/info/${item?.id}`}>
                                  <Button color="primary" variant="contained">
                                    Инфо.
                                  </Button>
                                </Link>
                                <Box sx={{ ml: 2 }}>
                                  <Link to={`/app/products/edit/${item.id}`}>
                                    <Button color="primary" variant="contained">
                                      Редакт.
                                    </Button>
                                  </Link>
                                </Box>
                                <Box sx={{ ml: 2 }}>
                                  <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => onDelete(item.id)}
                                  >
                                    Отменить публ.
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage={
                            <span>Кол-во строк на странице:</span>
                          }
                          rowsPerPageOptions={[10, 20, 30, 40, 50]}
                          colSpan={7}
                          count={count}
                          page={Number(searchParams.get('page'))}
                          rowsPerPage={
                            Number(searchParams.get('limit')) === 0
                              ? 10
                              : Number(searchParams.get('limit'))
                          }
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeLimit}
                        />
                        {console.log(Number(searchParams.get('limit')))}
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
        <ModalErrors
          isOpenModal={modalErrors}
          setIsOpenModal={setModalErrors}
        />
      </Box>
    </>
  );
};

export default ProductListMy;
