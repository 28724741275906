import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Input,
  Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet, usePost } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import { ChevronLeft } from 'react-feather';
import TokenStorage from '../../API/TokenStorage';

const CategoriesList = () => {
  const { categoryId } = useParams();

  const navigate = useNavigate();
  const confirm = useConfirm();
  const getU = useGet();
  const postU = usePost();
  const deleteU = useDelete();

  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [subcategories, setSubcategories] = useState([]);
  const [category, setCategory] = useState({});
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  const loadSubcategories = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    getU(`categories/${categoryId}`).then((resp) => {
      if (resp.status === 'success') {
        setCategory(resp.data.category);
      }
    });

    let endpoint = `categories/sub?page=${
      page + 1
    }&limit=${limit}&categoryId=${categoryId}`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setSubcategories(resp.data.subcategories);
          setCount(resp.data.currentCount || 0);
        }
        setIsDataLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setSubcategories([]);
        setCount(0);
        setIsDataLoading(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить подкатегорию?',
      onConfirm: () => {
        deleteU(`categories/sub/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadSubcategories();
            }
          })
          .catch((e) => {});
      }
    });
  };

  useEffect(() => {
    loadSubcategories();
  }, [page, limit]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Подкатегории товаров</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          "{category.name}" подкатегории
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Категории</li>
          <li>/</li>
          <li>Подкатегории</li>
        </ul>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          <Alert
            severity={alert.type}
            style={{
              display: alert.isVisible ? 'flex' : 'none',
              marginBottom: 10
            }}
          >
            {alert.txt}
          </Alert>
          {isDataLoading ? (
            <UserListSkelet />
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {TokenStorage.getUser().role == 'admin' && (
                  <Box sx={{ marginLeft: 2 }}>
                    <Link to={`/app/categories/sub/${categoryId}/add`}>
                      <Button color="primary" variant="contained">
                        Добавить подкатегорию
                      </Button>
                    </Link>
                  </Box>
                )}
              </Box>
              <Box sx={{ pt: 3 }}>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ minWidth: 400 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: 120 }}>Id</TableCell>
                            <TableCell sx={{ width: 350 }}>
                              Изображение
                            </TableCell>
                            <TableCell sx={{ width: 350 }}>Название</TableCell>
                            <TableCell sx={{ width: 350 }}>Приоритет</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {subcategories?.map((subcategory) => (
                            <TableRow hover key={subcategory.id}>
                              <TableCell sx={{ width: 80 }}>
                                {subcategory?.id}
                              </TableCell>
                              <TableCell>
                                <Box
                                  component="img"
                                  sx={{
                                    height: 80,
                                    width: 130,
                                    maxHeight: { xs: 233, md: 167 },
                                    maxWidth: { xs: 350, md: 250 }
                                  }}
                                  alt="img"
                                  src={
                                    subcategory?.imageUri
                                      ? `${process.env.REACT_APP_API_URL}/uploads/categories/${subcategory.imageUri}`
                                      : '/static/images/defphoto.jpg'
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                {subcategory?.name || '---'}
                              </TableCell>
                              <TableCell>{subcategory?.priority}</TableCell>
                              <TableCell>
                                <Box sx={{ display: 'flex' }}>
                                  {TokenStorage.getUser().role == 'admin' && (
                                    <Box sx={{ display: 'flex' }}>
                                      <Box sx={{ ml: 2 }}>
                                        <Link
                                          to={`/app/categories/sub/${categoryId}/edit/${subcategory.id}`}
                                        >
                                          <Button
                                            color="primary"
                                            variant="contained"
                                          >
                                            Редакт.
                                          </Button>
                                        </Link>
                                      </Box>
                                      <Box sx={{ ml: 2 }}>
                                        <Button
                                          color="error"
                                          variant="contained"
                                          onClick={() =>
                                            onDelete(subcategory.id)
                                          }
                                        >
                                          Удалить
                                        </Button>
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              labelRowsPerPage={
                                <span>Кол-во строк на странице:</span>
                              }
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={7}
                              count={count}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeLimit}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default CategoriesList;
